import {graphql, Link, useStaticQuery} from "gatsby"
import React from "react"
import {Row, Col, Button} from "react-bootstrap";
import * as styles from "./footer.module.css";
import Img from "gatsby-image";

const Footer = ()  => {

  const data = useStaticQuery(graphql`
    query FooterNavbarQuery {
      podcastIcon: file(relativePath: { eq: "footer/podcast-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      facebookIcon: file(relativePath: { eq: "footer/facebook-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      instagramIcon: file(relativePath: { eq: "footer/instagram-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      linkedinIcon: file(relativePath: { eq: "footer/linkedin-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      youtubeIcon: file(relativePath: { eq: "footer/youtube-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <footer>
      <div className={[styles.footerContainer, "container-fluid"].join(" ")}>
        <Row className={["mt-sm-5 justify-content-center", styles.navbar].join(" ")}>
          <Col xs={12} sm={12} md={{ span: 3, offset: 0 }}>
            <h4 className={styles.footerColumnTitle}><span>Quick</span> Links</h4>
            <p className={styles.footerLinks}><a href="https://www.momsonmaternity.com/">Moms on Maternity</a></p>
            <p className={styles.footerLinks}><Link to="/privacy-policy">Privacy and Policy</Link></p>
          </Col>

          <Col xs={12} sm={12} md={3} className={styles.socialMedia}>
            <h4 className={styles.footerColumnTitle}><span>Stay</span> in Touch</h4>
            <a variant="link"
                    href="https://www.instagram.com/momsonmaternity/"
                    target="_blank"
            ><Img className={styles.socialMediaIcon} fluid={data.instagramIcon.childImageSharp.fluid}/>
            </a>

            <a variant="link"
                    href="https://www.facebook.com/MomsOnMaternity/"
                    target="_blank"
            ><Img className={styles.socialMediaIcon} fluid={data.facebookIcon.childImageSharp.fluid}/></a>

            <a variant="link"
                    href="https://www.youtube.com/channel/UCCAGR4adI21ao1Jp7CTY32g"
                    target="_blank"
            ><Img className={styles.socialMediaIcon} fluid={data.youtubeIcon.childImageSharp.fluid}/></a>

            <a variant="link"
                    href="https://podcasts.apple.com/us/podcast/the-moms-on-maternity-podcast-with-aimee-cruz/id1556912487"
                    target="_blank"
            ><Img className={styles.socialMediaIcon} fluid={data.podcastIcon.childImageSharp.fluid}/></a>

            <a variant="link"
                    href="https://www.linkedin.com/company/moms-on-maternity/"
                    target="_blank"
            ><Img className={styles.socialMediaIcon} fluid={data.linkedinIcon.childImageSharp.fluid}/></a>
          </Col>

          <Col xs={12} sm={12} md={{ span: 3, offset: 0 }}>
            <h4 className={[styles.subscribeToNewsletterTitle, styles.footerColumnTitle].join(' ')}><span>Join </span>Tummy Time Club</h4>

            <Button type="submit" className={[styles.submitButton, styles.redirectButton]}>
              <a href="#formSection">Join us</a>
            </Button>

          </Col>
        </Row>


        <Row className={["mt-sm-3 justify-content-center ", styles.navbar].join(' ')}>
          <Col xs={10} sm={8} className={styles.copyRightMessage}>
            <p>&copy; 2021-2024 Tummy Time Club is a Trademarked Social Benefit Corporation. All rights reserved. </p>
          </Col>
        </Row>
      </div>
    </footer>
  )
};

export default Footer
