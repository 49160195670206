import * as React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { Col, Row, ListGroup } from "react-bootstrap";
import Logo from "./logo";
import MomsLogo from "./moms-logo";
import * as headerStyles from "./header.module.css";
import MainNav from "./navbar";

const Header = ({}) => (
  <>
    <div className={headerStyles.citiesTopNav}>
      <Row className={headerStyles.citiesContent}>
        <ListGroup horizontal className={headerStyles.groupList} >
          <ListGroup.Item className={[headerStyles.listGroupItem, headerStyles.momsLogoStyle]}>
            <div className={headerStyles.listGroupItem}>
              <MomsLogo />
            </div>
          </ListGroup.Item>
        </ListGroup>
      </Row>
    </div>

    <div className={headerStyles.headerNavMenu}>
      <Row className={[headerStyles.headerContainer, "justify-content-center"].join('')}>
        <Col className={[headerStyles.tummyTimeLogoStyle, "mt-3"].join('')}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
            className={headerStyles.logoStyle}
          ><Logo /></Link>
        </Col>
        <Col sm={{ span: 9, offset: 1 }} xs={6} className={headerStyles.headerContent}>
          <MainNav />
        </Col>
      </Row>
    </div>
  </>

);

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
