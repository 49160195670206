import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import SEO from "./seo";
import Footer from "./footer";

const Layout = ({ children, title, image, path, structuredData, description, disableLayoutPadding }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  title = title ? [data.site.siteMetadata.title, title].join(': ') : data.site.siteMetadata.title;
  disableLayoutPadding = disableLayoutPadding === undefined ? false : disableLayoutPadding;

  return (
    <div className="">
      <SEO title={title} path={path} image={image} structuredData={structuredData} description={description} />
      <Header siteTitle={data.site.siteMetadata.title} />

      <main className={[disableLayoutPadding ? '' : 'main-padding-top']}>{children}</main>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
