// extracted by mini-css-extract-plugin
export var navbar = "footer-module--navbar--0I+Sc";
export var socialMedia = "footer-module--socialMedia--ycozo";
export var footerContainer = "footer-module--footerContainer--SUxKe";
export var footerContent = "footer-module--footerContent--47V7C";
export var footerColumnTitle = "footer-module--footerColumnTitle--bHYl4";
export var socialMediaIcon = "footer-module--socialMediaIcon--fpmkD";
export var subscribeToNewsletterTitle = "footer-module--subscribeToNewsletterTitle--GPqkf";
export var emailInput = "footer-module--emailInput--dm7WT";
export var submitButton = "footer-module--submitButton--fx9Oe";
export var redirectButton = "footer-module--redirectButton--UAc4n";
export var alertMessage = "footer-module--alertMessage--E6PPS";
export var copyRightMessage = "footer-module--copyRightMessage--Tv9uU";
export var footerLinks = "footer-module--footerLinks--vj29S";