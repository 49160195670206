import React from "react"

import {graphql, useStaticQuery} from "gatsby";
import {Nav, Navbar} from "react-bootstrap";
import * as styles from "./navbar.module.css";

const MainNav = ({}) => {

  const data = useStaticQuery(graphql`
    query SiteNavbarQuery {
      site {
        siteMetadata {
          menuLinks {
            title
            link
          }
        }
      }
    }
  `);

  const menuLinks = data.site.siteMetadata.menuLinks;

    return (
    <Navbar expand="lg" className={styles.navbar}>
      <Navbar.Toggle aria-controls={styles.basicNavbarNav} />
      <Navbar.Collapse id={styles.basicNavbarNav} >
        <Nav className="mr-auto navButton">
          {menuLinks.map(item => (
            <div key={item.title}>
             <Nav.Link href={item.link}>{item.title}</Nav.Link>
            </div>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
};

export default MainNav;
