/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import env from "../config/env";

function Seo({ description, lang, meta, title, path, link, scripts, structuredData, image }) {
  const { site, logo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        logo: file(relativePath: { eq: "logo.png" }) {
          publicURL
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image || env.domain + logo.publicURL,
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:url`,
          content: path ? env.domain + path : env.domain,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: image || env.domain + logo.publicURL,
        },
        {
          name: `google-site-verification`,
          content: 'fIDYCIh26rdBC-2vYnicesSpaZZfnGNvn0sykZstzZM',
        },
        {
          name: `fb:app_id`,
          content: "263215610792219",
        },
      ].concat(meta)}
      link={[].concat(link)}
    >
    {scripts.map(script => (
      <script key={script.id} type={script.type} src={script.src}></script>
    ))}
    {structuredData ? <script type="application/ld+json">{JSON.stringify(structuredData)}</script> : "" }
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en_us`,
  meta: [],
  description: ``,
  link: [
    {
      rel: 'stylesheet',
      href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
      integrity: 'sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm',
      crossorigin: 'anonymous',
    },
    {
      rel: "stylesheet",
      href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css'
    }
  ],
  scripts: [
    {
      id: "vimeo",
      type: "text/javascript",
      src: "https://player.vimeo.com/api/player.js",
    },
  ],
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  structuredData: PropTypes.object
}

export default Seo
